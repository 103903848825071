<template>
     <div class="preview_area show_sidebar workout_config" v-if="modelValue">
        <div class="global_setting">
            <button class="close_btn" @click="$emit('update:modelValue', false);"><i class="fas fa-long-arrow-alt-left"></i></button>
            <div class="tabs_content">
                <div class="section_container">
                    <div class="block_library">
                        <div class="lib_head">
                            <div class="filter_search">
                                <input type="text" name="search" placeholder="Search...">
                                <button type="button" class="search_btn">
                                    <i class="fas fa-search"></i>
                                </button>
                            </div>
                            <button type="button" class="add_exercise" @click="newExercise = true;"><i class="fas fa-plus"></i>Create Custom Exercise</button>
                        </div>
                        <ul class="att_tags">
                            <li v-for="(tag, t) in selectedTags" :key="t" :class="tag.type">{{ tag.title }} <span class="delete_btn" @click="deleteAtt(t)"><i class="fas fa-times"></i></span></li>
                        </ul>
                        <div class="result_wpr new">
                            <div class="actions">
                                <ul>
                                    <li class="optionDrops contacts-tabs" @click="bulkOpt = !bulkOpt" v-if="selectedLibraryItems.length" v-click-outside="closeBulkAction">
                                        Bulk Action<i class="fas fa-angle-down"></i>
                                        <div class="dropdown_wpr" :class="bulkOpt ? 'active' : ''">
                                            <ul>
                                                <li @click="addToWorkout">Add To A Workout</li>
                                                <li @click="addToWorkout">Add To A Favorites</li>
                                                <li @click="confirmScreen = true;" class="danger" v-if="tab !== 'thrive-exs'">Delete</li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li class="optionDrops ml-auto"><span @click="selectedTags = []" v-if="selectedTags.length">Clear FIlter</span></li>
                                    <li class="optionDrops contacts-tabs" @click="sortingList = !sortingList"  v-click-outside="closeSortingDropdown">
                                        {{ filterTitle }} <i class="fas fa-angle-down"></i>
                                        <div class="dropdown_wpr" :class="sortingList ? 'active' : ''">
                                            <ul>
                                                <li v-for="(filter, f) in filters" :key="f" :class="{'active' : filter.title == filterTitle}" @click="sortingFilter(filter)">
                                                    {{ filter.title }}
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <ul class="tab_sec">
                            <li :class="tab === 'thrive-exs' ? 'active' : ''" @click="tab = 'thrive-exs'">Thrive Excercises</li>
                            <li :class="tab === 'my-exs' ? 'active' : ''" @click="tab = 'my-exs'">My Excercises</li>
                            <li :class="tab === 'favorites' ? 'active' : ''" @click="tab = 'favorites'">My Favorites</li>
                        </ul>
                        <div class="content_panel" v-if="tab === 'thrive-exs'">
                            <ul class="list" v-if="blockLibrary.length">
                                <li v-for="(item, i) in blockLibrary" :key="i" :class="{ selected : selectedLibraryItems.includes(`item-${i}`) }">
                                    <div class="action_items">
                                        <label :for="`library-item-${i}`" class="checkbox">
                                            <input type="checkbox" :id="`library-item-${i}`" :value="`item-${i}`" v-model="selectedLibraryItems" hidden>
                                            <span><i class="fas fa-check"></i></span>
                                        </label>
                                        <button type="button" class="pointer" @click="addFavorites(item)"><i :class="favoriteList.some(att => att.name === item.name) ? 'fas fa-heart' : 'far fa-heart'"></i></button>
                                    </div>
                                    <div class="video_wpr">
                                        <div class="video-overlay" @click="show_video = true;"></div>
                                        <iframe :src="item.link" class="loom-wrapper" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                                    </div>
                                    <h6>{{ item.name }}</h6>
                                </li>
                            </ul>
                            <div class="empty_section" v-else>
                                <h1>Looks like you don't have any Exercises yet.</h1>
                            </div>
                            <div class="load_more" v-if="blockLibrary.length">
                                <img src="@/assets/images/load_more.gif">Loading
                            </div>
                        </div>
                        <div class="content_panel" v-if="tab === 'my-exs'">
                            <ul class="list" v-if="my_exercises.length">
                                <li v-for="(item, i) in my_exercises" :key="i" :class="{ selected : selectedLibraryItems.includes(`item-${i}`) }">
                                    <div class="action_items">
                                        <label :for="`library-item-${i}`" class="checkbox">
                                            <input type="checkbox" :id="`library-item-${i}`" :value="`item-${i}`" v-model="selectedLibraryItems" hidden>
                                            <span><i class="fas fa-check"></i></span>
                                        </label>
                                        <button type="button" class="pointer" @click="addFavorites(item)"><i :class="favoriteList.some(att => att.name === item.name) ? 'fas fa-heart' : 'far fa-heart'"></i></button>
                                    </div>
                                    <div class="video_wpr">
                                        <div class="video-overlay" @click="show_video = true;"></div>
                                        <iframe :src="item.link" class="loom-wrapper" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                                    </div>
                                    <h6>{{ item.name }}</h6>
                                </li>
                            </ul>
                            <div class="empty_section" v-else>
                                <h1>Looks like you don't have any Exercises yet.</h1>
                            </div>
                        </div>
                        <div class="content_panel" v-if="tab === 'favorites'">
                            <ul class="list" v-if="favoriteList.length">
                                <li v-for="(item, i) in favoriteList" :key="i" :class="{ selected : selectedLibraryItems.includes(`item-${i}`) }">
                                    <div class="action_items">
                                        <label :for="`library-item-${i}`" class="checkbox">
                                            <input type="checkbox" :id="`library-item-${i}`" :value="`item-${i}`" v-model="selectedLibraryItems" hidden>
                                            <span><i class="fas fa-check"></i></span>
                                        </label>
                                        <button type="button" class="pointer" @click="addFavorites(item)"><i :class="favoriteList.some(att => att.name === item.name) ? 'fas fa-heart' : 'far fa-heart'"></i></button>
                                    </div>
                                    <div class="video_wpr">
                                        <div class="video-overlay" @click="show_video = true;"></div>
                                        <iframe :src="item.link" class="loom-wrapper" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                                    </div>
                                    <h6>{{ item.name }}</h6>
                                </li>
                            </ul>
                            <div class="empty_section" v-else>
                                <h1>Looks like you don't have any Favorites yet.</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="attribute_bar show">
                <h3>Narrow Your Search</h3>
                <div class="toggle_btn">
                    <a v-if="allAttr" @click="openAllGrp" class="toggle_btn">Open All</a>
                    <a v-else @click="closeAllGrp" class="toggle_btn">Close All</a>
                </div>
                <div class="attribute_grp">
                    <h4 @click="accFunc($event)">Exercise Type <i class="fas fa-chevron-down"></i></h4>
                    <ul>
                        <li v-for="(tag, t) of exercise_type" :key="`exercise-${t}`" @click="selectTag(tag.title, 'exercise')">
                            <label :for="`exercise-${t}`" class="checkbox">
                                <span :class="{'active' : selectedTags.some(att => att.title === tag.title)}"><i class="fas fa-check"></i></span>
                                {{ tag.title }}
                            </label>
                        </li>
                    </ul>
                </div>
                <div class="attribute_grp">
                    <h4 @click="accFunc($event)">Body Part <i class="fas fa-chevron-down"></i></h4>
                    <ul>
                        <li v-for="(tag, t) of body_part_filters" :key="`part-${t}`" @click="selectTag(tag.title, 'part')">
                            <label :for="`part-${t}`" class="checkbox">
                                <span :class="{'active' : selectedTags.some(att => att.title === tag.title)}"><i class="fas fa-check"></i></span>
                                {{ tag.title }}
                            </label>
                        </li>
                    </ul>
                </div>
                <div class="attribute_grp">
                    <h4 @click="accFunc($event)">Muscle Target <i class="fas fa-chevron-down"></i></h4>
                    <ul>
                        <li v-for="(tag, t) of muscle_target_filters" :key="`muscle-${t}`" @click="selectTag(tag.title, 'muscle')">
                            <label :for="`muscle-${t}`" class="checkbox">
                                <span :class="{'active' : selectedTags.some(att => att.title === tag.title)}"><i class="fas fa-check"></i></span>
                                {{ tag.title }}
                            </label>
                        </li>
                    </ul>
                </div>
                <div class="attribute_grp">
                    <h4 @click="accFunc($event)">Synergist <i class="fas fa-chevron-down"></i></h4>
                    <ul>
                        <li v-for="(tag, t) of synergist_filters" :key="`equipment-${t}`" @click="selectTag(tag.title, 'synergist')">
                            <label :for="`equipment-${t}`" class="checkbox">
                                <span :class="{'active' : selectedTags.some(att => att.title === tag.title)}"><i class="fas fa-check"></i></span>
                                {{ tag.title }}
                            </label>
                        </li>
                    </ul>
                </div>
                <div class="attribute_grp">
                    <h4 @click="accFunc($event)">Equipment <i class="fas fa-chevron-down"></i></h4>
                    <ul>
                        <li v-for="(tag, t) of equipment_filters" :key="`equipment-${t}`" @click="selectTag(tag.title, 'equipment')">
                            <label :for="`equipment-${t}`" class="checkbox">
                                <span :class="{'active' : selectedTags.some(att => att.title === tag.title)}"><i class="fas fa-check"></i></span>
                                {{ tag.title }}
                            </label>
                        </li>
                    </ul>
                </div>
                <div class="attribute_grp">
                    <h4 @click="accFunc($event)">Skeletal Action<i class="fas fa-chevron-down"></i></h4>
                    <ul>
                        <li v-for="(tag, t) of skeletal_action" :key="`skeletal-${t}`" @click="selectTag(tag.title, 'skeletal')">
                            <label :for="`skeletal-${t}`" class="checkbox">
                                <span :class="{'active' : selectedTags.some(att => att.title === tag.title)}"><i class="fas fa-check"></i></span>
                                {{ tag.title }}
                            </label>
                        </li>
                    </ul>
                </div>
                <div class="attribute_grp">
                    <h4 @click="accFunc($event)">Movement <i class="fas fa-chevron-down"></i></h4>
                    <ul>
                        <li v-for="(tag, t) of movement_filters" :key="`movement-${t}`" @click="selectTag(tag.title, 'movement')">
                            <label :for="`movement-${t}`" class="checkbox">
                                <span :class="{'active' : selectedTags.some(att => att.title === tag.title)}"><i class="fas fa-check"></i></span>
                                {{ tag.title }}
                            </label>
                        </li>
                    </ul>
                </div>
                <div class="attribute_grp">
                    <h4 @click="accFunc($event)">Body Position <i class="fas fa-chevron-down"></i></h4>
                    <ul>
                        <li v-for="(tag, t) of body_position" :key="`position-${t}`" @click="selectTag(tag.title, 'position')">
                            <label :for="`position-${t}`" class="checkbox">
                                <span :class="{'active' : selectedTags.some(att => att.title === tag.title)}"><i class="fas fa-check"></i></span>
                                {{ tag.title }}
                            </label>
                        </li>
                    </ul>
                </div>
                <div class="attribute_grp">
                    <h4 @click="accFunc($event)">Contraction <i class="fas fa-chevron-down"></i></h4>
                    <ul>
                        <li v-for="(tag, t) of contraction_filters" :key="`contraction-${t}`" @click="selectTag(tag.title, 'contraction')">
                            <label :for="`contraction-${t}`" class="checkbox">
                                <span :class="{'active' : selectedTags.some(att => att.title === tag.title)}"><i class="fas fa-check"></i></span>
                                {{ tag.title }}
                            </label>
                        </li>
                    </ul>
                </div>
                <div class="attribute_grp">
                    <h4 @click="accFunc($event)">Difficulty <i class="fas fa-chevron-down"></i></h4>
                    <ul>
                        <li v-for="(tag, t) of difficulty_filters" :key="`difficulty-${t}`" @click="selectTag(tag.title, 'difficulty')">
                            <label :for="`difficulty-${t}`" class="checkbox">
                                <span :class="{'active' : selectedTags.some(att => att.title === tag.title)}"><i class="fas fa-check"></i></span>
                                {{ tag.title }}
                            </label>
                        </li>
                    </ul>
                </div>
                <div class="attribute_grp">
                    <h4 @click="accFunc($event)">Avatar Gender <i class="fas fa-chevron-down"></i></h4>
                    <ul>
                        <li v-for="(tag, t) of avatar_gender" :key="`difficulty-${t}`" @click="selectTag(tag.title, 'avatar')">
                            <label :for="`difficulty-${t}`" class="checkbox">
                                <span :class="{'active' : selectedTags.some(att => att.title === tag.title)}"><i class="fas fa-check"></i></span>
                                {{ tag.title }}
                            </label>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="attribute_bar fixed" :class="{show : addAttributes}">
                <h3>Attribute List <button @click="addAttributes = false;"><i class="fas fa-times"></i></button></h3>
                <div class="toggle_btn">
                    <a v-if="allAttr" @click="openAllGrp" class="toggle_btn">Open All</a>
                    <a v-else @click="closeAllGrp" class="toggle_btn">Close All</a>
                </div>
                <div class="attribute_grp">
                    <h4 @click="accFunc($event)">Exercise Type <i class="fas fa-chevron-down"></i></h4>
                    <ul>
                        <li v-for="(tag, t) of exercise_type" :key="`exercise-${t}`" @click="selectAtt(tag.title, 'exercise')">
                            <label :for="`exercise-${t}`" class="checkbox">
                                <span :class="{'active' : selectedAtt.some(att => att.title === tag.title)}"><i class="fas fa-check"></i></span>
                                {{ tag.title }}
                            </label>
                        </li>
                    </ul>
                </div>
                <div class="attribute_grp">
                    <h4 @click="accFunc($event)">Body Part <i class="fas fa-chevron-down"></i></h4>
                    <ul>
                        <li v-for="(tag, t) of body_part_filters" :key="`part-${t}`" @click="selectAtt(tag.title, 'part')">
                            <label :for="`part-${t}`" class="checkbox">
                                <span :class="{'active' : selectedAtt.some(att => att.title === tag.title)}"><i class="fas fa-check"></i></span>
                                {{ tag.title }}
                            </label>
                        </li>
                    </ul>
                </div>
                <div class="attribute_grp">
                    <h4 @click="accFunc($event)">Muscle Target <i class="fas fa-chevron-down"></i></h4>
                    <ul>
                        <li v-for="(tag, t) of muscle_target_filters" :key="`muscle-${t}`" @click="selectAtt(tag.title, 'muscle')">
                            <label :for="`muscle-${t}`" class="checkbox">
                                <span :class="{'active' : selectedAtt.some(att => att.title === tag.title)}"><i class="fas fa-check"></i></span>
                                {{ tag.title }}
                            </label>
                        </li>
                    </ul>
                </div>
                <div class="attribute_grp">
                    <h4 @click="accFunc($event)">Synergist <i class="fas fa-chevron-down"></i></h4>
                    <ul>
                        <li v-for="(tag, t) of synergist_filters" :key="`equipment-${t}`" @click="selectAtt(tag.title, 'synergist')">
                            <label :for="`equipment-${t}`" class="checkbox">
                                <span :class="{'active' : selectedAtt.some(att => att.title === tag.title)}"><i class="fas fa-check"></i></span>
                                {{ tag.title }}
                            </label>
                        </li>
                    </ul>
                </div>
                <div class="attribute_grp">
                    <h4 @click="accFunc($event)">Equipment <i class="fas fa-chevron-down"></i></h4>
                    <ul>
                        <li v-for="(tag, t) of equipment_filters" :key="`equipment-${t}`" @click="selectAtt(tag.title, 'equipment')">
                            <label :for="`equipment-${t}`" class="checkbox">
                                <span :class="{'active' : selectedAtt.some(att => att.title === tag.title)}"><i class="fas fa-check"></i></span>
                                {{ tag.title }}
                            </label>
                        </li>
                    </ul>
                </div>
                <div class="attribute_grp">
                    <h4 @click="accFunc($event)">Skeletal Action<i class="fas fa-chevron-down"></i></h4>
                    <ul>
                        <li v-for="(tag, t) of skeletal_action" :key="`skeletal-${t}`" @click="selectAtt(tag.title, 'skeletal')">
                            <label :for="`skeletal-${t}`" class="checkbox">
                                <span :class="{'active' : selectedAtt.some(att => att.title === tag.title)}"><i class="fas fa-check"></i></span>
                                {{ tag.title }}
                            </label>
                        </li>
                    </ul>
                </div>
                <div class="attribute_grp">
                    <h4 @click="accFunc($event)">Movement <i class="fas fa-chevron-down"></i></h4>
                    <ul>
                        <li v-for="(tag, t) of movement_filters" :key="`movement-${t}`" @click="selectAtt(tag.title, 'movement')">
                            <label :for="`movement-${t}`" class="checkbox">
                                <span :class="{'active' : selectedAtt.some(att => att.title === tag.title)}"><i class="fas fa-check"></i></span>
                                {{ tag.title }}
                            </label>
                        </li>
                    </ul>
                </div>
                <div class="attribute_grp">
                    <h4 @click="accFunc($event)">Body Position <i class="fas fa-chevron-down"></i></h4>
                    <ul>
                        <li v-for="(tag, t) of body_position" :key="`position-${t}`" @click="selectAtt(tag.title, 'position')">
                            <label :for="`position-${t}`" class="checkbox">
                                <span :class="{'active' : selectedAtt.some(att => att.title === tag.title)}"><i class="fas fa-check"></i></span>
                                {{ tag.title }}
                            </label>
                        </li>
                    </ul>
                </div>
                <div class="attribute_grp">
                    <h4 @click="accFunc($event)">Contraction <i class="fas fa-chevron-down"></i></h4>
                    <ul>
                        <li v-for="(tag, t) of contraction_filters" :key="`contraction-${t}`" @click="selectAtt(tag.title, 'contraction')">
                            <label :for="`contraction-${t}`" class="checkbox">
                                <span :class="{'active' : selectedAtt.some(att => att.title === tag.title)}"><i class="fas fa-check"></i></span>
                                {{ tag.title }}
                            </label>
                        </li>
                    </ul>
                </div>
                <div class="attribute_grp">
                    <h4 @click="accFunc($event)">Difficulty <i class="fas fa-chevron-down"></i></h4>
                    <ul>
                        <li v-for="(tag, t) of difficulty_filters" :key="`difficulty-${t}`" @click="selectAtt(tag.title, 'difficulty')">
                            <label :for="`difficulty-${t}`" class="checkbox">
                                <span :class="{'active' : selectedAtt.some(att => att.title === tag.title)}"><i class="fas fa-check"></i></span>
                                {{ tag.title }}
                            </label>
                        </li>
                    </ul>
                </div>
                <div class="attribute_grp">
                    <h4 @click="accFunc($event)">Avatar Gender <i class="fas fa-chevron-down"></i></h4>
                    <ul>
                        <li v-for="(tag, t) of avatar_gender" :key="`difficulty-${t}`" @click="selectAtt(tag.title, 'avatar')">
                            <label :for="`difficulty-${t}`" class="checkbox">
                                <span :class="{'active' : selectedAtt.some(att => att.title === tag.title)}"><i class="fas fa-check"></i></span>
                                {{ tag.title }}
                            </label>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="modal" v-if="workoutSetup" :class="{active : workoutSetup}">
            <div class="modal_container">
                <div class="modal_header border-bottom">
                    <div class="modal_info">
                        <h1>Add To Workout</h1>
                    </div>
                    <button class="close_btn left_out" @click="workoutSetup = false"><i class="fas fa-long-arrow-alt-right"></i></button>
                </div>
                <div class="modal_body">
                    <div class="setting_wpr">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Select A Workout</label>
                                <div class="field_wpr">
                                    <Multiselect v-model="workoutList.value" v-bind="workoutList" placeholder="Select One"></Multiselect>
                                </div>
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Which Block?</label>
                                <div class="field_wpr">
                                    <Multiselect v-model="blockItems.value" v-bind="blockItems" placeholder="Select One"></Multiselect>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal_footer">
                    <button class="btn cancel_btn" @click="workoutSetup = false">Cancel</button>
                    <button class="btn save_btn">Add</button>
                </div>
            </div>
        </div>
        <div class="modal secondary new_exercise" v-if="newExercise" :class="{active : newExercise}">
            <div class="modal_container">
                <h2>
                    Add New Exercise
                    <button type="button" class="favorite_btn" @click="newExerFav = !newExerFav"><i :class="newExerFav ? 'fas fa-heart' : 'far fa-heart'"></i></button>
                    <button type="button" class="close_btn" @click="newExercise = false"><i class="fas fa-times"></i></button>
                </h2>
                <div class="setting_wpr w-100">
                    <div class="form_grp">
                        <div class="group_item">
                            <label class="input_label">Exercise Name</label>
                            <div class="field_wpr">
                                <input type="text" placeholder="ex: New Exercise">
                            </div>
                        </div>
                    </div>
                    <div class="form_grp">
                        <div class="group_item">
                            <label class="input_label">Exercise Description</label>
                            <div class="field_wpr">
                                <textarea name="" id="" cols="30" rows="10" placeholder="Description goes here.."></textarea>
                            </div>
                        </div>
                    </div>
                    <h3 class="sub_header mt-2 mb-2">Video Link</h3>
                    <div class="edit_section mb-2">
                        <label for="video_url" class="switch_option capsule_btn p-0 mt-0 mb-2 border-0">
                            <h5 class="large mb-0">Video URL</h5>
                            <input type="radio" id="video_url" :value="1" v-model="video_type" hidden>
                            <div><span></span></div>
                        </label>
                        <div class="setting_wpr" v-if="video_type == 1">
                            <div class="form_grp p-0">
                                <div class="group_item">
                                    <div class="field_wpr">
                                        <input type="text" name="welcome_video_link" placeholder="ex:https://www.youtube.com/watch?v=eaRQF-7hhmo"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <label for="embed_video" class="switch_option capsule_btn p-0 mt-4 mb-0 border-0">
                            <h5 class="large m-0">Embeded Video</h5>
                            <input type="radio" id="embed_video" :value="2" v-model="video_type" hidden>
                            <div><span></span></div>
                        </label>
                        <div class="setting_wpr" v-if="video_type == 2">
                            <div class="form_grp py-2">
                                <div class="group_item">
                                    <div class="field_wpr">
                                        <textarea name="welcome_video_embed_code" placeholder="embeded code here.."></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="attribute_area">
                        <h4>
                            <label>Attributes</label>
                            <a v-if="selectedAtt.length" @click="selectedAtt = [];">Clear Attributes</a>
                            <a @click="addAttributes = !addAttributes;">{{addAttributes ? 'Close' : 'Add'}} Attributes</a>
                        </h4>
                        <ul class="att_tags">
                            <li v-for="(tag, t) in selectedAtt" :key="t" :class="tag.type">{{ tag.title }} <span class="delete_btn" @click="removeAtt(t)"><i class="fas fa-times"></i></span></li>
                        </ul>
                    </div>
                    <div class="form_grp">
                        <div class="group_item">
                            <label class="input_label">Instructions</label>
                            <div class="field_wpr">
                                <textarea name="" id="" cols="30" rows="10" placeholder="Instruction goes here.."></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="action_wpr mt-4">
                    <button class="btn cancel_btn mx-1" @click="newExercise = false; addAttributes = false;">Cancel</button>
                    <button class="btn save_btn mx-1" @click="addAttrFunc">Add</button>
                </div>
            </div>
        </div>
        <div class="modal secondary" v-if="confirmScreen" :class="{active : confirmScreen}">
            <div class="modal_container">
                <img src="@/assets/images/alert.svg" alt="" class="alert_img">
                <h2>Are You Sure !</h2>
                <div class="setting_wpr">
                    <h6>This action is permanent. You will not be able to recover these exercises. Please type DELETE to remove these exercises.</h6>
                    <div class="form_grp">
                        <div class="group_item">
                            <div class="field_wpr has_suffix">
                                <input type="text" placeholder="DELETE" >
                                <span class="suffix">Delete</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="action_wpr mt-4">
                    <button class="btn cancel_btn mx-1" @click="confirmScreen = false">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from '@vueform/multiselect';
export default{
    name: 'ExerciseLibrary',
    data(){
        return{
            tab: 'thrive-exs',
            newExercise: false,
            newExerFav: false,
            video_type: 1,
            bulkOpt: false,
            workoutSetup: false,
            confirmScreen: false,
            addAttributes: false,
            selectedAtt:[],
            workoutList: {
                mode: 'single',
                value: '',
                options: [
                    { value: 'Workout 1', label: 'Workout 1' },
                    { value: 'Workout 2', label: 'Workout 2' },
                    { value: 'Workout 3', label: 'Workout 3' },
                ],
            },
            blockItems: {
                mode: 'single',
                value: '',
                options: [
                    { value: 'Warm-up', label: 'Warm-up' },
                    { value: 'Progression', label: 'Progression' },
                    { value: 'Finisher', label: 'Finisher' },
                    { value: 'Recovery', label: 'Recovery' },
                    { value: 'Rest Period', label: 'Rest Period' },
                ],
            },
            attributes: {
                mode: 'single',
                value: [],
                options: [],
            },
            sortingList: false,
            filterTitle: 'Sort By',
            filters: [
                { title: 'A-Z'},
                { title: 'Z-A'},
                { title: 'Date Created'},
                { title: 'Last Modified'},
            ],
            selectedLibraryItems: [],
            blockLibrary: [
                {
                    name: 'Seated Shoulder Press',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/shoulders/Smith%20Shoulder%20Press.mp4'
                },
                {
                    name: 'Dead Hang',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Dead%20Hang%20Stretch.mp4'
                },
                {
                    name: 'Thoracic Bridge',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Thoracic%20Bridge%20Stretching.mp4'
                },
                {
                    name: 'Seated Shoulder Press',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/shoulders/Smith%20Shoulder%20Press.mp4'
                },
                {
                    name: 'Dead Hang',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Dead%20Hang%20Stretch.mp4'
                },
                {
                    name: 'Thoracic Bridge',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Thoracic%20Bridge%20Stretching.mp4'
                },
                {
                    name: 'Seated Shoulder Press',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/shoulders/Smith%20Shoulder%20Press.mp4'
                },
                {
                    name: 'Dead Hang',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Dead%20Hang%20Stretch.mp4'
                },
                {
                    name: 'Thoracic Bridge',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Thoracic%20Bridge%20Stretching.mp4'
                },
                {
                    name: 'Seated Shoulder Press',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/shoulders/Smith%20Shoulder%20Press.mp4'
                },
                {
                    name: 'Dead Hang',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Dead%20Hang%20Stretch.mp4'
                },
                {
                    name: 'Thoracic Bridge',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Thoracic%20Bridge%20Stretching.mp4'
                },
                {
                    name: 'Seated Shoulder Press',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/shoulders/Smith%20Shoulder%20Press.mp4'
                },
                {
                    name: 'Dead Hang',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Dead%20Hang%20Stretch.mp4'
                },
                {
                    name: 'Thoracic Bridge',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Thoracic%20Bridge%20Stretching.mp4'
                },
                {
                    name: 'Seated Shoulder Press',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/shoulders/Smith%20Shoulder%20Press.mp4'
                },
            ],
            my_exercises:[
                {
                    name: 'Seated Shoulder Press',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/shoulders/Smith%20Shoulder%20Press.mp4'
                },
                {
                    name: 'Dead Hang',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Dead%20Hang%20Stretch.mp4'
                },
                {
                    name: 'Thoracic Bridge',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Thoracic%20Bridge%20Stretching.mp4'
                },
                {
                    name: 'Seated Shoulder Press',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/shoulders/Smith%20Shoulder%20Press.mp4'
                },
            ],
            favoriteList:[
                {
                    name: 'Dead Hang',
                    link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Dead%20Hang%20Stretch.mp4'
                },
            ],
            allAttr: 1,
            selectedTags:[],
            exercise_type:[
                { title: 'Balance' },
                { title: 'Calisthenics' },
                { title: 'Cardio' },
                { title: 'Conditioning' },
                { title: 'Crossfit' },
                { title: 'Finisher' },
                { title: 'Mind-body' },
                { title: 'Olympic Lifting' },
                { title: 'Plyometric' },
                { title: 'Power' },
                { title: 'Powerlifting ' },
                { title: 'Prehab' },
                { title: 'Recovery' },
                { title: 'SAQ' },
                { title: 'Stabilization' },
                { title: 'Strength' },
                { title: 'Stretching' },
                { title: 'Warm-up' },
                { title: 'Yoga' },
            ],
            body_part_filters: [
                { title: 'Neck'},
                { title: 'Shoulders'},
                { title: 'Chest'},
                { title: 'Rotator Cuff'},
                { title: 'Traps'},
                { title: 'Upper Back'},
                { title: 'Middle Back'},
                { title: 'Lower Back'},
                { title: 'Biceps'},
                { title: 'Triceps'},
                { title: 'Forearms'},
                { title: 'Abs-Core'},
                { title: 'Abductors'},
                { title: 'Adductors'},
                { title: 'Glutes'},
                { title: 'Hamstrings'},
                { title: 'Quadriceps'},
                { title: 'Lower Legs'},
            ],
            muscle_target_filters: [
                { title: 'Adductor Brevis'},
                { title: 'Adductor Longus'},
                { title: 'Adductor Magnus'},
                { title: 'Biceps Brachii'},
                { title: 'Brachialis'},
                { title: 'Brachioradialis'},
                { title: 'Deep Rotators'},
                { title: 'Deltoid Anterior'},
                { title: 'Deltoid Lateral'},
                { title: 'Deltoid Posterior'},
                { title: 'Erector Spinae'},
                { title: 'Gastrocnemius'},
                { title: 'Gluteus Maximus'},
                { title: 'Gluteus Medius'},
                { title: 'Gluteus Minimus'},
                { title: 'Gracilis'},
                { title: 'Hamstrings'},
                { title: 'Iliopsoas'},
                { title: 'Infraspinatus'},
                { title: 'Latisimus Dorsi'},
                { title: 'Levator Scapula'},
                { title: 'Pectinuius'},
                { title: 'Pectoralis Major'},
                { title: 'Pectoralis Minor'},
                { title: 'Quadriceps'},
                { title: 'Rectus Abdominus'},
                { title: 'Sartorius'},
                { title: 'Soleus'},
                { title: 'Splenius'},
                { title: 'Sternocleidomastoid'},
                { title: 'Subscapularis'},
                { title: 'Tensor Fasciae Latae'},
                { title: 'Teres Major'},
                { title: 'Teres Minor'},
                { title: 'Tibialis Anterior'},
                { title: 'Transverse Abdominus'},
                { title: 'Trapezius Lower'},
                { title: 'Trapezius Middle'},
                { title: 'Trapezius Upper'},
                { title: 'Triceps Brachii'},
                { title: 'Wrist Extensors'},
                { title: 'Wrist Flexors'},
            ],
            synergist_filters: [
                { title: 'Adductor Brevis'},
                { title: 'Adductor Longus'},
                { title: 'Adductor Magnus'},
                { title: 'Biceps Brachii'},
                { title: 'Brachialis'},
                { title: 'Brachioradialis'},
                { title: 'Deep Rotators'},
                { title: 'Deltoid Anterior'},
                { title: 'Deltoid Lateral'},
                { title: 'Deltoid Posterior'},
                { title: 'Erector Spinae'},
                { title: 'Gastrocnemius'},
                { title: 'Gluteus Maximus'},
                { title: 'Gluteus Medius'},
                { title: 'Gluteus Minimus'},
                { title: 'Gracilis'},
                { title: 'Hamstrings'},
                { title: 'Iliopsoas'},
                { title: 'Infraspinatus'},
                { title: 'Latisimus Dorsi'},
                { title: 'Levator Scapula'},
                { title: 'Pectinuius'},
                { title: 'Pectoralis Major'},
                { title: 'Pectoralis Minor'},
                { title: 'Quadriceps'},
                { title: 'Rectus Abdominus'},
                { title: 'Sartorius'},
                { title: 'Soleus'},
                { title: 'Splenius'},
                { title: 'Sternocleidomastoid'},
                { title: 'Subscapularis'},
                { title: 'Tensor Fasciae Latae'},
                { title: 'Teres Major'},
                { title: 'Teres Minor'},
                { title: 'Tibialis Anterior'},
                { title: 'Transverse Abdominus'},
                { title: 'Trapezius Lower'},
                { title: 'Trapezius Middle'},
                { title: 'Trapezius Upper'},
                { title: 'Triceps Brachii'},
                { title: 'Wrist Extensors'},
                { title: 'Wrist Flexors'},
            ],
            equipment_filters: [
                { title: 'Ab Roller'},
                { title: 'Band'},
                { title: 'Barbell'},
                { title: 'Battle Rope'},
                { title: 'Body Weight'},
                { title: 'Balance Board'},
                { title: 'Balance Cushion'},
                { title: 'Bench'},
                { title: 'Bike'},
                { title: 'Bosu'},
                { title: 'Box'},
                { title: 'Cable-Single'},
                { title: 'Cable-Duel'},
                { title: 'Cones'},
                { title: 'Dip Stands'},
                { title: 'Dumbbells'},
                { title: 'EZ Bar'},
                { title: 'Foam Roller'},
                { title: 'Indian Club'},
                { title: 'Jacobs Ladder'},
                { title: 'Jump Rope'},
                { title: 'Kettlebell'},
                { title: 'Lacrosse Ball'},
                { title: 'Landmine'},
                { title: 'Leg Sled'},
                { title: 'Mace'},
                { title: 'Machine'},
                { title: 'Mat'},
                { title: 'Medicine Ball'},
                { title: 'Mini Band'},
                { title: 'Neutral Grip Bar'},
                { title: 'Parrallettes '},
                { title: 'Plate'},
                { title: 'Power Band'},
                { title: 'Pullup Bar'},
                { title: 'Push Sled'},
                { title: 'Rope Machine'},
                { title: 'Rower'},
                { title: 'Rebounder'},
                { title: 'Sand Bag'},
                { title: 'Scooter'},
                { title: 'Sliders'},
                { title: 'Smith Machine'},
                { title: 'Stability Ball'},
                { title: 'Suspension Trainer'},
                { title: 'Tire'},
                { title: 'Trap Bar'},
                { title: 'Versa Climber'},
            ],
            skeletal_action:[
                { title: 'Abduction' },
                { title: 'Adduction' },
                { title: 'Circumduction' },
                { title: 'Depression' },
                { title: 'Dorsiflexion' },
                { title: 'Elevation' },
                { title: 'Eversion' },
                { title: 'Extension' },
                { title: 'Flexion' },
                { title: 'Horizontal Abduction' },
                { title: 'Horizontal Adduction' },
                { title: 'Hyperextension' },
                { title: 'Inversion' },
                { title: 'Knee Valgus' },
                { title: 'Knee Varus' },
                { title: 'Knee Varus' },
                { title: 'Plantarflexion' },
                { title: 'Pronation' },
                { title: 'Protraction' },
                { title: 'Radial Deviation' }, 
                { title: 'Reposition' },
                { title: 'Retraction' },
                { title: 'Rotation' },
                { title: 'Scapular Depression' },
                { title: 'Scapular Elevation' },
                { title: 'Scapular Protraction' },
                { title: 'Scapular Retraction' },
                { title: 'Supination' },
                { title: 'Ulnar Deviation' },
                { title: 'Opposition' },
            ],
            movement_filters: [
                { title: 'Balancing'},
                { title: 'Bending'},
                { title: 'Bounding'},
                { title: 'Breathing'},
                { title: 'Carrying'},
                { title: 'Catching'},
                { title: 'Climbing'},
                { title: 'Crawling'},
                { title: 'Curling'},
                { title: 'Gripping'},
                { title: 'Hitting'},
                { title: 'Hopping'},
                { title: 'Jumping'},
                { title: 'Kicking'},
                { title: 'Leaping'},
                { title: 'Lifting'},
                { title: 'Lunging'},
                { title: 'Lying down'},
                { title: 'Pulling'},
                { title: 'Punching'},
                { title: 'Pushing'},
                { title: 'Reaching'},
                { title: 'Rolling'},
                { title: 'Rotating'},
                { title: 'Running'},
                { title: 'Sitting'},
                { title: 'Sprinting'},
                { title: 'Squatting'},
                { title: 'Standing'},
                { title: 'Stepping'},
                { title: 'Stretching'},
                { title: 'Throwing'},
                { title: 'Turning'},
                { title: 'Twisting'},
                { title: 'Walking'},
            ],
            body_position:[
                { title: 'Arched'},
                { title: 'Bent over'},
                { title: 'Bridged'},
                { title: 'Cross-legged'},
                { title: 'Fetal'},
                { title: 'Inclined'},
                { title: 'Half-Kneeling'},
                { title: 'Hinged'},
                { title: 'Inverted'},
                { title: 'Kneeling'},
                { title: 'Leaning'},
                { title: 'Plank'},
                { title: 'Prone'},
                { title: 'Quadruped'},
                { title: 'Reclined'},
                { title: 'Sidelined'},
                { title: 'Side-lying'},
                { title: 'Sitting'},
                { title: 'Single Leg'},
                { title: 'Single Arm'},
                { title: 'Squatting'},
                { title: 'Standing'},
                { title: 'Supine'},
                { title: 'Twisted'},
            ],
            contraction_filters:[
                { title: 'Concentric'},
                { title: 'Eccentric'},
                { title: 'Isometric'},
            ],
            difficulty_filters: [
                { title: 'Beginner'},
                { title: 'Intermediate'},
                { title: 'Advanced'},
            ],
            avatar_gender:[
                { title: 'Male'},
                { title: 'Female'},
            ]
        }
    },

    components:{
        Multiselect,
    },

    props:{
        modelValue: Boolean,
    },
    emits: ['update:modelValue'],

    methods:{
        addToWorkout(){
            const vm = this;
            if(vm.selectedLibraryItems.length){
                vm.workoutSetup = true;
            }
        },
        sortingFilter (filter) {
            const vm = this;

            vm.filterTitle = filter.title;
        },
        closeSortingDropdown () {
            const vm = this;

            vm.sortingList = false;
        },
        closeBulkAction(){
            const vm = this;

            vm.bulkOpt = false;
        },
        accFunc(ev){
            const vm = this;

            let el = ev.currentTarget.closest('.attribute_grp');
            if(el.classList.contains('active')){
                el.classList.remove('active');
                vm.allAttr = 1;
            }else{
                el.classList.add('active');
                vm.allAttr = 0;
            }
        },
        openAllGrp () {
            const vm = this;

            let allParentElm = document.querySelectorAll('.attribute_grp');

            for(let i = 0; i < allParentElm.length; i++){
                allParentElm[i].classList.add('active');
            }

            vm.allAttr = 0;
        },

        closeAllGrp () {
            const vm = this;
            let allParentElm = document.querySelectorAll('.attribute_grp');

            for (let i = 0; i < allParentElm.length; i++) {
                allParentElm[i].classList.remove('active');
            }

            vm.allAttr = 1;
        },
        selectTag(tag, cat){
            const vm = this;

            const has_item = vm.selectedTags.findIndex((att) => att.title === tag);

            if(has_item == -1){
                vm.selectedTags.push({
                    type: cat,
                    title: tag,
                });
            } else{
                vm.selectedTags.splice(has_item, 1);
            }
        },
        deleteAtt(t){
            const vm = this;

            vm.selectedTags.splice(t, 1);
        },
        addFavorites(item){
            const vm = this;

            const has_item = vm.favoriteList.findIndex((att) => att.name === item.name);

            if(has_item == -1){
                vm.favoriteList.push(item);
            } else{
                vm.favoriteList.splice(has_item, 1);
            }
        },
        selectAtt(tag, cat){
            const vm = this;

            const has_item = vm.selectedAtt.findIndex((att) => att.title === tag);

            if(has_item == -1){
                vm.selectedAtt.push({
                    type: cat,
                    title: tag,
                });
            } else{
                vm.selectedAtt.splice(has_item, 1);
            }
        },
        removeAtt(t){
            const vm = this;

            vm.selectedAtt.splice(t, 1);
        },
    }
}
</script>

<style scoped>
.tab_sec{
    width: 100%;
    margin: 0 auto 10px auto;
    border: 0;
}
.tab_sec li.active {
    border-radius: 5px;
}
.result_wpr.new{
    overflow: inherit;
    width: 100%;
}
.video_wpr {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    margin: 0;
    border-radius: 5px;
    overflow: hidden;
}
.video_wpr iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
}
.section_container {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    padding: 30px 50px;
}
.content_panel{
    border-radius: 8px;
    background: #fff;
    position: relative;
    padding: 30px;
    width: 100%;
}
.block_library{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
}
.block_library .lib_head{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
}
.block_library .filter_search {
    height: 34px;
    border-radius: 20px;
    padding: 0 34px 0 0;
    position: relative;
    background: #fff;
    max-width: 400px;
    width: 100%;
}
.block_library .filter_search input{
    font-size: 11px;
    line-height: 34px;
    color: #666;
    border: 0;
    width: 100%;
    padding: 0 15px;
    background: transparent;
    box-sizing: border-box;
}
.block_library .filter_search .search_btn {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    border: 0;
    font-size: 11px;
    color: #333;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
}
.add_exercise{
    font-size: 11px;
    line-height: 16px;
    font-weight: 500;
    color: #121525;
    cursor: pointer;
}
.add_exercise i{
    margin-right: 5px;
    font-size: 9px;
}
.block_library ul.list{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    width: 100%;
}
.block_library ul.list > li{
    padding: 20px 10px;
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    position: relative;
}
.block_library ul li h6{
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    font-weight:500;
}
.block_library ul li .action_items{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 10px;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}
.block_library ul li .action_items button{
    color: #777;
}
.block_library ul li:hover .action_items, .block_library ul li.selected .action_items{
    opacity: 1;
}
.block_library ul li .action_items label.checkbox span {
    width: 12px;
    flex: 0 0 12px;
    height: 12px;
    border-radius: 2px;
}
.block_library ul li .action_items label.checkbox span i{
    font-size: 8px;
}
/* .block_library ul li .action_items a{
    cursor: pointer;
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
}
.block_library ul li .action_items a i{
    font-size: 9px;
    padding-right: 4px;
} */
.attribute_bar{
    padding: 15px 0;
    background: #fff;
    border-left: 1px solid #e9e9e9;
    width: 230px;
    max-height: 100vh;
    overflow-y: overlay;
    position: relative;
    margin-right: -230px;
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction:column;
}
.attribute_bar.show{
    margin-right: 0;
}
.attribute_bar::-webkit-scrollbar{
    width: 4px;
}
.attribute_bar::-webkit-scrollbar-thumb{
    border-radius: 2px;
    background: #cacaca;
}
.attribute_bar h3{
    font-size: 15px;
    line-height: 20px;
    color: #121525;
    font-weight: 500;
    padding: 0 20px 5px 20px;
    display: flex;
    align-items: center;
}
.attribute_bar h3 button{
    font-size: 13px;
    color: #999;
    margin-left: auto;
    cursor: pointer;
}
.toggle_btn{
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px 15px 20px;
}
.toggle_btn a{
    padding: 4px 15px;
    background: #f5f5f5;
    font-size: 11px;
    line-height: 14px;
    color: #121525;
    font-weight: 500;
    display: block;
    border-radius: 10px;
    cursor: pointer;
}
.attribute_grp{
    border-top: 1px solid #f5f5f5;
}
.attribute_grp h4{
    padding: 12px 20px;
    font-size: 13px;
    line-height: 20px;
    color: #121525;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.attribute_grp h4 i{
    font-size: 11px;
    color: #999;
    transition: all 0.3s ease-in-out;
}
.attribute_grp ul{
    padding: 0 15px;
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}
.attribute_grp.active ul{
    max-height: 10000px;
}
.attribute_grp.active h4 i{
    transform: rotate(-180deg);
}
.attribute_bar ul li{
    font-size: 13px;
    line-height: 18px;
    color: #5a5a5a;
    font-weight: 400;
    padding: 10px 15px;
}
.attribute_bar ul li:last-child{
    margin-bottom: 15px;
}
.attribute_bar ul li label.checkbox, .dropdown_wpr ul li label.checkbox{
    justify-content: flex-start;
    gap: 10px;
    cursor: pointer;
}
.dropdown_wpr ul li label.checkbox span i{
    margin: 0;        
}
.attribute_bar ul li label.checkbox span.active i, .dropdown_wpr ul li label.checkbox span.active i{
    transform: scale(1);
    opacity: 1;
}
.att_tags{
    /* background: #fff; */
    border-radius: 6px;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
}
.att_tags li{
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5a5a5a;
    position: relative;
}
.att_tags .delete_btn{
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    background: #eb1414;
    color: #fff;
    position: absolute;
    right: -5px;
    top: -5px;
    cursor: pointer;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}
.att_tags li:hover .delete_btn{
    opacity: 1;
}
.att_tags li.exercise{
    border-color: #88d3d9;
}
.att_tags li.position{
    border-color: #b99191;
}
.att_tags li.contraction{
    border-color: #968bca;
}
.att_tags li.muscle{
    border-color: #76afff;
}
.att_tags li.movement{
    border-color: #cb72ff;
}
.att_tags li.equipment{
    border-color: #56e094;
}
.att_tags li.skeletal{
    border-color: #fb76c8;
}
.att_tags li.difficulty{
    border-color: #f2a31d;
}

.modal.new_exercise .modal_container{
    padding: 20px 30px 0 30px;
    max-height: 600px;
    overflow-y: scroll;
}
.modal.new_exercise .modal_container::-webkit-scrollbar{
    display: none;
}
.modal.new_exercise h2{
    margin-bottom: 5px;
    font-size: 22px;
    line-height: 28px;
    position: relative;
    width: 100%;
    padding: 0 20px;
    text-align: center;
}
.modal.new_exercise h2 .favorite_btn{
    position: absolute;
    left: 0;
    top: 5px;
    cursor: pointer;
    font-size: 16px;
    color: #777;
}
.modal.new_exercise h2 .close_btn{
    position: absolute;
    right: 0;
    top: 5px;
    cursor: pointer;
    font-size: 16px;
    color: #777;
}
.attribute_area{
    padding: 15px 0 10px 0;
}
.attribute_area h4{
    display: flex;
    align-items: center;
    padding-bottom: 6px;
    gap: 10px;
}
.attribute_area h4 label{
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    color: #121525;
    margin-right: auto;
}
.attribute_area h4 a{
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    color: #5a5a5a;
    cursor: pointer;
}
.attribute_area .att_tags{
    border: 1px solid #CBCDD3;
    border-radius: 6px;
    padding: 10px 15px;
    justify-content: flex-start;
    min-height: 50px;
}
.attribute_bar.fixed{
    position: fixed;
    right: -230px;
    top: 0;
    bottom: 0;
    z-index: 13;
    transition: all 0.3s ease-in-out;
}
.attribute_bar.fixed.show{
    right: 0;
}
.new_exercise .action_wpr{
    width: calc(100% + 60px);
    border-top: 1px solid #e9e9e9;
    background: #fff;
    padding: 20px 25px;
    margin: 0 -30px;
    position: sticky;
    bottom: 0;
}
</style>